.table-users-admin td.status .icon.close {
  color: #f00;
}
.table-users-admin td.status .icon.check {
  color: #008000;
}

.ui.pagination .item.disabled,
.ui.pagination .icon.disabled {
  display: inline-block; /* o display: initial; */
}/*# sourceMappingURL=TableUsers.css.map */