.side-menu-admin .ui.menu {
  width: 250px !important;
  overflow: hidden;
  padding-top: 4em;
  z-index: 99 !important;
}
.side-menu-admin .ui.menu i.icon {
  float: initial !important;
  margin: 0 !important;
  margin-right: 10px !important;
}
.side-menu-admin .content {
  position: absolute;
  right: 0;
  padding: 20px;
  width: calc(100% - 250px);
}

.icon.home {
  color: #F6AF19;
}

.icon.file {
  color: #F6AF19;
}

.icon.alternate {
  color: #F6AF19;
}

.icon.university {
  color: #F6AF19;
}

.icon.users {
  color: #F6AF19;
}/*# sourceMappingURL=SideMenu.css.map */