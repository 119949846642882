.admin-layout{
    width: 100%;
    display: grid;
    grid: 55px / 1fr;
   /*
    grid-template-areas:
    "menu",
    "content";

min-height: 100vh;

&__menu{
    grid-area: menu;
}

&__main-content{
    grid-area: content;
}*/
}